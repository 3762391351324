import groupBy from 'lodash/groupBy'
import map from 'lodash/map'
import type { PropertyTypes } from '@/types'

export const nameToColorPropertyMap: { [key: string]: string } = {
  'On Sale': '#3957D3',
  Launch: '#F0C724',
  'Presale (EOI)': '#43CF58',
  Announcement: '#F6865F',
  Pending: '#EE4925',
  'Sold Out': '#CFCFD0',
}

const codeToNameMap: { [key: string]: string } = {
  rooms_studio: 'Studio',
  rooms_1: '1 bedroom',
  rooms_2: '2 bedroom',
  rooms_3: '3 bedroom',
  rooms_4: '4 bedroom',
  rooms_5: '5 bedroom',
  rooms_6: '6 bedroom',
  rooms_7: '7 bedroom',
}

const nameToCodeMap: { [key: string]: string } = {
  Studio: 'rooms_studio',
  '1 Bedroom': 'rooms_1',
  '1 BR': 'rooms_1',
  '2 Bedroom': 'rooms_2',
  '2 BR': 'rooms_2',
  '3 Bedroom': 'rooms_3',
  '3 BR': 'rooms_3',
  '4 Bedroom': 'rooms_4',
  '4 BR': 'rooms_4',
  '5 Bedroom': 'rooms_5',
  '5 BR': 'rooms_5',
  '6 Bedroom': 'rooms_6',
  '6 BR': 'rooms_6',
  '7 Bedroom': 'rooms_7',
  '7 BR': 'rooms_7',
}

export const getPropertyUnits = (items: PropertyTypes.Apartment[]): any => {
  const apartments = items.map((item: PropertyTypes.Apartment) => {
    return {
      key: item.rooms_count.en.length ? nameToCodeMap[item.rooms_count.en] : nameToCodeMap[item.title.en],
      ...item,
    }
  })

  const units = map(
    groupBy(apartments, (apartment) => apartment.key),
    (value, key) => ({
      name: key,
      title: codeToNameMap[key],
      price_min: Math.min(...value.map((apartment) => parseFloat(apartment.price.min))),
      area_min: Math.min(...value.map((apartment) => parseFloat(apartment.area_min?.m2))),
      area_max: Math.max(...value.map((apartment) => parseFloat(apartment.area_max?.m2))),
      count: value.reduce((sum, apartment) => sum + parseInt(apartment.sale_units_count, 10), 0),
      currency: value[0].price.currency,
      items: value,
    })
  )
  const sortOrder = Object.keys(codeToNameMap)
  const sortedUnits = units.sort((a, b) => {
    return sortOrder.indexOf(a.name) - sortOrder.indexOf(b.name)
  })

  return sortedUnits
}

const percentKeys = [
  { key: 'on_booking_percent', name: 'Booking fee' },
  { key: 'on_construction_percent', name: 'During Construction' },
  { key: 'on_handover_percent', name: 'On Handover', date: 'Handover' },
  { key: 'post_handover_percent', name: 'Post Handover' },
]

type Plan = {
  key: string
  name: string
  percent: string
  date?: string
  repeatCount?: string | string[]
}

export const useGetPropertyPaymentPlans = (items: PropertyTypes.PaymentPlan[]): Plan[][] => {
  return items.map((item) => {
    const plans = percentKeys
      .filter((keyItem) => item[keyItem.key] && item[keyItem.key].length > 0)
      .map((keyItem) => {
        const plan: Plan = {
          key: keyItem.key,
          name: keyItem.name,
          percent: `${item[keyItem.key]}%`,
        }
        if (keyItem.date) {
          plan.date = keyItem.date
        }
        if (keyItem.key === 'post_handover_percent') {
          if (item.period_after_handover.count.length) {
            plan.date = `${item.period_after_handover.count} ${item.period_after_handover.period} after handover`
          }
          if (item.period_after_handover.repeat_count.length) {
            plan.repeatCount = item.period_after_handover.repeat_count
          }
        }
        return plan
      })

    if (item.additional) {
      const additionalPlans = Array.isArray(item.additional)
        ? item.additional.map((additionalItem: PropertyTypes.AdditionalPaymentPlan) => ({
            key: additionalItem.percent.length ? 'additional_percent' : 'additional_fix',
            name: additionalItem.title.en,
            percent: additionalItem.percent.length
              ? `${additionalItem.percent}%`
              : `${additionalItem.fix} ${item.currency}`,
          }))
        : [
            {
              key: item.additional.percent.length ? 'additional_percent' : 'additional_fix',
              name: item.additional.title.en,
              percent: item.additional.percent.length
                ? `${item.additional.percent}%`
                : `${item.additional.fix} ${item.currency}`,
            },
          ]
      if (additionalPlans.length > 0) {
        plans.splice(1, 0, ...additionalPlans)
      }
    }

    return plans
  })
}
